import * as React from 'react';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import { Box, Typography, TextField, Button, Grid } from '@mui/material';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {'Copyright © '}
      <Link href="https://www.legistool.com/">Legistool&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

const handleSubmit = (event) => {
  event.preventDefault();
  const formData = new FormData(event.currentTarget);
  const name = formData.get('name');
  const company = formData.get('company');
  const email = formData.get('email');
  const message = formData.get('message');

  // Crea el mailto link
  const mailtoLink = `mailto:infolegistool@gmail.com?subject=Contact from ${name}&body=Name: ${name}%0D%0ACompany: ${company}%0D%0AEmail: ${email}%0D%0A%0D%0AMessage: ${message}`;

  // Abre el cliente de correo
  window.location.href = mailtoLink;
};

export default function Footer() {
  return (
    <Container
      id='Contactenos'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Contactenos
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: 'flex',
          justifyContent: 'center', // Centra el formulario horizontalmente
          alignItems: 'center', // Centra el formulario verticalmente
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%'
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={4}>
            <TextField
              label="Nombre"
              name="name"
              variant="outlined"
              fullWidth
              required
              size="small" // Hace que el campo sea más compacto
              sx={{ mb: 2 }}
            />
            <TextField
              label="Empresa"
              name="company"
              variant="outlined"
              fullWidth
              size="small" // Hace que el campo sea más compacto
              sx={{ mb: 2 }}
            />
            <TextField
              label="Correo Electrónico"
              name="email"
              type="email"
              variant="outlined"
              fullWidth
              required
              size="small" // Hace que el campo sea más compacto
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Mensaje"
              name="message"
              variant="outlined"
              fullWidth
              multiline
              rows={6}
              required
              size="small" // Hace que el campo sea más compacto
              sx={{ mb: 2 }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth // El botón ocupa toda la anchura
            >
              Contactar
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <div>

          <Copyright />
        </div>
        <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color: 'text.secondary',
          }}
        >
          <IconButton
            color="inherit"
            href="https://www.linkedin.com/in/marco-quesada-sandi-513a18a2/"
            aria-label="LinkedIn"
            sx={{ alignSelf: 'center' }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
