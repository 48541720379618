import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Grid, Avatar } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const miembros = [
  {
    nombre: 'MS.c Marco Quesada Sandí',
    puesto: 'Director de Derecho Ambiental',
    foto: 'https://legistool-images.nyc3.digitaloceanspaces.com/public/Marco.jpg',
    descripcion: 'Marco se encuentra incorporado al Colegio de Abogados de Costa Rica desde noviembre del 2011 y ejerce como Notario Público desde setiembre del 2016. Posee más de 8 años de experiencia en el área de Derecho Ambiental; ha participado en procesos de Due Diligence ambientales, litigio en Tribunales de Justicia y el Tribunal Ambiental Administrativo, es auditor líder de normas ISO de ambiente, calidad, salud y seguridad ocupacional; auditor interno de normas de energía, inocuidad alimentaria, así como cuantificador y verificador de Carbono Neutralidad.',
    cv: {
      columnaIzquierda: [
        {
          titulo: 'Educación',
          elementos: [
            'Maestría en Derecho Ambiental. Universidad de Costa Rica.',
            'Especialidad en Derecho Notarial y Registral. Universidad de Costa Rica.',
            'Licenciatura en Derecho. Universidad de Costa Rica.'
          ]
        },
        {
          titulo: 'Certificaciones',
          elementos: [
            'Cuantificación de emisiones y carbono neutralidad integrado. Normas INTE/ISO 14064-1 e INTE B5. INTECO. 2021',
            'Auditor líder en sistemas integrados de calidad, ambiente y seguridad y salud en el trabajo según las normas INTE/ISO 9001:2015, INTE/ISO 14001:2015, INTE/ISO 45001:2018 e INTE/ISO 19011:2018. INTECO. 2021.',
            'Auditor interno FSSC 22000. Sistemas de- inocuidad de los alimentos. INTECO. 2020',
            'Auditor interno INTE/ISO 50001:2018. Sistemas de gestión en de la energía. INTECO. 2020',
            'Auditor interno INTE/ISO 45001:2018. Sistemas de gestión en salud ocupacional. INTECO. 2018',
            'Verificadores de gases de efecto invernadero. INTECO. 2017',
            'Certificado de entrenamiento en los lineamientos en Reporte GRI Standards. 2019',
            'Auditor Interno INTE/ISO 9001:2015. Sistemas de gestión de la calidad. INTECO. 2018',
            'Auditor Interno INTE/ISO 14001:2015. Sistemas de gestión ambiental. INTECO. 2016'
          ]
        },

      ],
      columnaDerecha: [
        {
          titulo: 'Proyectos',
          elementos: [
            'Diagnósticos de cumplimiento legal en las normas ISO 9001, IS0 14001, ISO 45001, ISO 50001, FSSC 22000 en empresas de países como Costa Rica, Nicaragua, El Salvador, Guatemala, Honduras y Estados Unidos en diferentes industrias: alimentos, químicas, gestión de residuos, productos y servicios.',
            'Participación en procesos de Due Diligence Ambiental para la fusión y adquisición en áreas de bienes raises, telecomunicaciones, productos y servicios, industria hotelera e industria aeronáutica.', 
            'Tiene amplio conocimiento en proceso de Gestión de Gases de Efecto Invernadero, desarrollando e implementando el sistema de gestión. También ha participado en proyectos de GEI para empresas en el área de agroindustria, médica y servicios.',
            'Ha realizado evaluaciones y verificaciones internas del Sistema de Gestión de Gases de Efecto Invernadero de diferentes empresas en la norma ISO 14064 y GHG Protocol, incluidos una empresa con operaciones en más de nueve países.',
            'Posee experiencia en brindar entrenamientos, así como capacitaciones en gestión ambiental, manejo y gestión integral de los residuos ordinarios, así como peligrosos y procesos de Carbono Neutralidad.',
            'Participó en la creación y mesas de diálogo del Reglamento de Gestión Integral de Residuos de República Dominicana.'
          ]
        },
      ],
    },
  },
];

export default function Equipo() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
    id="Equipo"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Equipo
      </Typography>
      <Box sx={{ width: '100%' }}>
        {miembros.map((miembro, index) => (
          <Box key={index} sx={{ mb: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Avatar src={miembro.foto} alt={miembro.nombre} sx={{ width: 200, height: 200 }} />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography variant="h6">{miembro.nombre}</Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {miembro.puesto}
                </Typography>
                <Typography variant="body1" sx={{ mt: 2 }}>
                  {miembro.descripcion}
                </Typography>
              </Grid>
            </Grid>
            <Accordion
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              sx={{ mt: 2 }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}d-content`}
                id={`panel${index}d-header`}
              >
                <Typography component="h3" variant="subtitle2">
                  Trayectoria profesional
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    {miembro.cv.columnaIzquierda.map((seccion, idx) => (
                      <Box key={idx} sx={{ mb: 2 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                          {seccion.titulo}
                        </Typography>
                        <ul>
                          {seccion.elementos.map((elemento, index) => (
                            <li key={index}>{elemento}</li>
                          ))}
                        </ul>
                      </Box>
                    ))}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {miembro.cv.columnaDerecha.map((seccion, idx) => (
                      <Box key={idx} sx={{ mb: 2 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                          {seccion.titulo}
                        </Typography>
                        <ul>
                          {seccion.elementos.map((elemento, index) => (
                            <li key={index}>{elemento}</li>
                          ))}
                        </ul>
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
      </Box>
    </Container>
  );
}
