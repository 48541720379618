import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import VerifiedIcon from '@mui/icons-material/Verified';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';

const items = [
  {
    icon: <AppRegistrationIcon />,
    title: 'Regulaciones',
    description:
      'Este módulo permite personalizar la legislación aplicable a cada organización, por medio de filtros específicos. Contiene una descripción detallada del requisito legal aplicable para cada norma.',
  },
  {
    icon: <VerifiedIcon />,
    title: 'Permisos',
    description:
      'Permite contar con los documentos relevantes de cada organización, en donde se establecerán las fechas de vencimiento de cada uno de ellos y los usuarios alertas automáticas para recordar su renovación.',
  },
  {
    icon: <InsertDriveFileIcon />,
    title: 'Documentos',
    description:
      'Mediante este módulo se pueden respaldar expedientes, así como documentos de diversas materias para una consulta rápida y accesible.',
  },
  {
    icon: <AssuredWorkloadIcon />,
    title: 'Checklist de auditoría',
    description:
      'Dentro de los servicios principales que brindamos se encuentran las auditorías de complimiento legal. Con este módulo se permite visualizar la totalidad de los requisitos legales aplicables a las operaciones de la organización, así como el resultado de la evaluación.',
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Métricas de auditoría',
    description:
      'Este componente permite visualizar gráficamente los resultados de la auditoría legal.',
  },
  {
    icon: <SupportAgentRoundedIcon />,
    title: 'Resultados de auditoría.',
    description:
      'Permite respaldar el reporte completo posterior al servicio de auditoría.',
  }
];

export default function ComponentesLegistool() {
  return (
    <Box
      id="Componentes Legistool"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#06090a',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            Componentes Legistool
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
          A continuación, se presentan los componentes de nuestra herramienta:
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'grey.800',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
