import React, { useState } from 'react';
import {
    Container,
    TextField,
    Button,
    Typography,
    Grid,
    Autocomplete,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Delete, Edit, ContentCopy } from '@mui/icons-material';

const Categorias = () => {

    // Lista pre-cargada de categorías
    const [categorias, setCategorias] = useState([
        { id: 1, nombre: 'Tecnología', descripcion: 'Categoría relacionada con avances tecnológicos y software.' },
        { id: 2, nombre: 'Salud', descripcion: 'Categoría enfocada en bienestar y medicina.' },
        { id: 3, nombre: 'Finanzas', descripcion: 'Categoría sobre economía, inversiones y banca.' },
        { id: 4, nombre: 'Educación', descripcion: 'Categoría para temas educativos y de aprendizaje.' }
    ]);

    const [nombre, setNombre] = useState('');
    const [errorNombre, setErrorNombre] = useState('');
    const [editandoId, setEditandoId] = useState(null);
    const [filtro, setFiltro] = useState('');

    const handleInputChange = (event) => {
        const value = event.target.value;
        setNombre(value);
        if (value.trim() === '') {
            setErrorNombre('El nombre es requerido');
        } else {
            setErrorNombre('');
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const nombreExistente = categorias.some(
            (categoria) => categoria.nombre === nombre && categoria.id !== editandoId
        );

        if (nombre.trim() === '') {
            setErrorNombre('El nombre es requerido');
            return;
        } else if (nombreExistente) {
            setErrorNombre('El nombre ya está en uso');
            return;
        }

        setErrorNombre('');

        const nuevaCategoria = {
            id: editandoId !== null ? editandoId : categorias.length + 1,
            nombre,
        };

        if (editandoId !== null) {
            const nuevasCategorias = categorias.map((categoria) =>
                categoria.id === editandoId ? nuevaCategoria : categoria
            );
            setCategorias(nuevasCategorias);
            setEditandoId(null);
        } else {
            setCategorias([...categorias, nuevaCategoria]);
        }

        setNombre('');
    };

    const handleDelete = (id) => {
        setCategorias(categorias.filter((categoria) => categoria.id !== id));
    };

    const handleEdit = (id) => {
        const categoriaEdit = categorias.find((categoria) => categoria.id === id);
        if (categoriaEdit) {
            setNombre(categoriaEdit.nombre);
            setEditandoId(id);
        }
    };

    const handleCopy = (id) => {
        const categoriaCopy = categorias.find((categoria) => categoria.id === id);
        if (categoriaCopy) {
            const nuevaCategoria = {
                id: categorias.length + 1,
                nombre: `${categoriaCopy.nombre} (copia)`,
            };
            setCategorias([...categorias, nuevaCategoria]);
        }
    };

    const handleFiltroChange = (event, value) => {
        setFiltro(value);
    };

    const categoriasFiltradas = categorias.filter((categoria) =>
        categoria.nombre.toLowerCase().includes(filtro?.toLowerCase() || '')
    );

    const columns = [
        { field: 'nombre', headerName: 'Nombre', width: 200 },
        { field: 'descripcion', headerName: 'Descripción', width: 400 },
        {
            field: 'acciones',
            headerName: 'Acciones',
            width: 150,
            renderCell: (params) => (
                <>
                    <Button onClick={() => handleEdit(params.id)} size="small">
                        <Edit fontSize="small" />
                    </Button>
                    <Button onClick={() => handleCopy(params.id)} size="small">
                        <ContentCopy fontSize="small" />
                    </Button>
                    <Button onClick={() => handleDelete(params.id)} size="small">
                        <Delete fontSize="small" />
                    </Button>
                </>
            ),
        },
    ];

    return (
        <Container
            style={{
                marginTop: '1em',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <Typography variant="h4" component="h1" gutterBottom>
                Categorías
            </Typography>
            <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={6}>
                    <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <TextField
                            label="Nombre"
                            variant="outlined"
                            fullWidth
                            value={nombre}
                            onChange={handleInputChange}
                            margin="normal"
                            error={!!errorNombre}
                            helperText={errorNombre}
                            required
                            name="Nombre"
                        />
                        <Button type="submit" variant="contained" color="primary">
                            {editandoId !== null ? 'Guardar Cambios' : 'Agregar Categoría'}
                            </Button>
                        </form>
                    </Grid>

                </Grid>
                <Grid container spacing={3} justifyContent="center" marginTop={3} alignContent={"center"}>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            freeSolo
                            options={categorias.map((categoria) => categoria.nombre)}
                            onInputChange={handleFiltroChange}
                            renderInput={(params) => (
                                <TextField {...params} label="Filtrar Categorías" variant="outlined" fullWidth />
                            )}
                        />
                    </Grid>
                </Grid>
    
                <div style={{ height: 400, width: '100%', marginTop: 20 }}>
                    <DataGrid
                        rows={categoriasFiltradas}
                        columns={columns}
                        pageSize={5}
                    />
                </div>
            </Container>
        );
    };
    
    export default Categorias;
    