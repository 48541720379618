// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
// Replace this import with the mock
// import axios from 'axios';
import axios from './mocks/axios'; // Use the mock axios instead

const App = () => {
  useEffect(() => {
    const refreshToken = () => {
      const token = localStorage.getItem('token');
      if (token) {
        axios.post('/api/refresh-token', { token })
          .then(response => {
            localStorage.setItem('token', response.data.token);
          })
          .catch(error => {
            console.error('Token refresh failed', error);
          });
      }
    };

    const intervalId = setInterval(refreshToken, 15 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/dashboard/*" element={<PrivateRoute><DashboardPage /></PrivateRoute>} />
      </Routes>
    </Router>
  );
};

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  return token ? children : <Navigate to="/login" />;
};

export default App;
