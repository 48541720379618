import React, { useState, useEffect } from 'react';
import { Grid, Paper, Select, MenuItem, Typography, List, ListItem, ListItemText } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';

// Datos de ejemplo
const data = [
  { id: 1, requerimiento: 'Nombre del requerimiento Cat 1-1', organizacion: 'Organización 1', sucursal: 'Sucursal 1-1', estado: 'Cumple', periodo: 2024 },
  { id: 2, requerimiento: 'Nombre del requerimiento Cat 1-2', organizacion: 'Organización 1', sucursal: 'Sucursal 1-1', estado: 'Cumple', periodo: 2024 },
  { id: 2, requerimiento: 'Nombre del requerimiento Cat 1-3', organizacion: 'Organización 1', sucursal: 'Sucursal 1-1', estado: 'Cumple', periodo: 2024 },
  { id: 2, requerimiento: 'Nombre del requerimiento Cat 1-4', organizacion: 'Organización 1', sucursal: 'Sucursal 1-1', estado: 'Observacion', periodo: 2024 },
  { id: 2, requerimiento: 'Nombre del requerimiento Cat 1-5', organizacion: 'Organización 1', sucursal: 'Sucursal 1-1', estado: 'Observacion', periodo: 2024 },
  { id: 3, requerimiento: 'Nombre del requerimiento Cat 1-3', organizacion: 'Organización 1', sucursal: 'Sucursal 1-2', estado: 'No Cumple', periodo: 2024 },
  { id: 4, requerimiento: 'Nombre del requerimiento Cat 2-1', organizacion: 'Organización 2', sucursal: 'Sucursal 2-1', estado: 'Cumple', periodo: 2024 },
  { id: 5, requerimiento: 'Nombre del requerimiento Cat 2-2', organizacion: 'Organización 2', sucursal: 'Sucursal 2-1', estado: 'Observacion', periodo: 2024 },
  { id: 6, requerimiento: 'Nombre del requerimiento Cat 2-3', organizacion: 'Organización 2', sucursal: 'Sucursal 2-2', estado: 'No Cumple', periodo: 2024 },
  { id: 7, requerimiento: 'Nombre del requerimiento Cat 3-1', organizacion: 'Organización 3', sucursal: 'Sucursal 3-1', estado: 'Cumple', periodo: 2024 },
  { id: 8, requerimiento: 'Nombre del requerimiento Cat 3-2', organizacion: 'Organización 3', sucursal: 'Sucursal 3-1', estado: 'Observacion', periodo: 2024 },
  { id: 9, requerimiento: 'Nombre del requerimiento Cat 3-3', organizacion: 'Organización 3', sucursal: 'Sucursal 3-2', estado: 'No Cumple', periodo: 2024 },
  { id: 10, requerimiento: 'Nombre del requerimiento Cat 4-1', organizacion: 'Organización 3', sucursal: 'Sucursal 3-2', estado: 'Cumple', periodo: 2024 },
  { id: 11, requerimiento: 'Nombre del requerimiento Cat 4-2', organizacion: 'Organización 3', sucursal: 'Sucursal 3-1', estado: 'Observacion', periodo: 2024 },
  { id: 12, requerimiento: 'Nombre del requerimiento Cat 4-3', organizacion: 'Organización 2', sucursal: 'Sucursal 2-2', estado: 'No Cumple', periodo: 2024 },
];

// Opciones de organización y sucursales
const organizaciones = [
  { nombre: 'Organización 1', elementos: ['Sucursal 1-1', 'Sucursal 1-2'] },
  { nombre: 'Organización 2', elementos: ['Sucursal 2-1', 'Sucursal 2-2'] },
  { nombre: 'Organización 3', elementos: ['Sucursal 3-1', 'Sucursal 3-2'] },
];

const COLORS = ['#4CAF50', '#FF9800', '#F44336']; // Colores para el gráfico

const Metricas = ({isAdmin}) => {
  const [filtroOrganizacion, setFiltroOrganizacion] = useState(isAdmin? '' : 'Organización 1');
  const [filtroSucursal, setFiltroSucursal] = useState(isAdmin? '' : 'Sucursal 1-1');
  const [sucursalOptions, setSucursalOptions] = useState([]);

  useEffect(() => {
    const organizacionSeleccionada = organizaciones.find(org => org.nombre === filtroOrganizacion);
    setSucursalOptions(organizacionSeleccionada ? organizacionSeleccionada.elementos : []);
    setFiltroSucursal('');
  }, [filtroOrganizacion]);

  const handleFiltroOrganizacionChange = (event) => setFiltroOrganizacion(event.target.value);
  const handleFiltroSucursalChange = (event) => setFiltroSucursal(event.target.value);

  // Filtrar datos
  const checklistsFiltrados = data.filter((checklist) => {
    return (
      (!filtroOrganizacion || checklist.organizacion === filtroOrganizacion) &&
      (!filtroSucursal || checklist.sucursal === filtroSucursal)
    );
  });

  // Calcular datos para el gráfico
  const calcularDatosGrafico = () => {
    const estados = checklistsFiltrados.reduce((acc, curr) => {
      acc[curr.estado] = (acc[curr.estado] || 0) + 1;
      return acc;
    }, {});

    const total = checklistsFiltrados.length;
    return [
      { name: 'Cumple', value: (estados['Cumple'] || 0) / total * 100 },
      { name: 'No Cumple', value: (estados['No Cumple'] || 0) / total * 100 },
      { name: 'Observacion', value: (estados['Observacion'] || 0) / total * 100 }
    ];
  };

  return (
    <div style={{ padding: '16px' }}>
      <Typography variant="h4" gutterBottom>
        Métricas
      </Typography>
      {/* Filtros */}
      {isAdmin && (
        <Paper style={{ padding: '16px', marginBottom: '16px' }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Select
              value={filtroOrganizacion}
              onChange={handleFiltroOrganizacionChange}
              fullWidth
              displayEmpty
            >
              <MenuItem value="">Organización</MenuItem>
              {organizaciones.map((org) => (
                <MenuItem key={org.nombre} value={org.nombre}>
                  {org.nombre}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <Select
              value={filtroSucursal}
              onChange={handleFiltroSucursalChange}
              fullWidth
              displayEmpty
              disabled={!filtroOrganizacion}
            >
              <MenuItem value="">Sucursal</MenuItem>
              {sucursalOptions.map((suc) => (
                <MenuItem key={suc} value={suc}>
                  {suc}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Paper>
      )}
      
      {/* Grid con dos columnas */}
      <Grid container spacing={2}>
        {/* Columna izquierda */}
        <Grid item xs={12} md={6}>
          <Paper style={{ padding: '16px' }}>
            <Typography variant="h6" gutterBottom>
              Requerimientos
            </Typography>
            <List>
              {checklistsFiltrados.map((checklist) => (
                <ListItem key={checklist.id}>
                  <ListItemText
                    primary={checklist.requerimiento}
                    secondary={`Estado: ${checklist.estado}`}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
        {/* Columna derecha */}
        <Grid item xs={12} md={6}>
          <Paper style={{ padding: '16px', height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Distribución de Estados
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie data={calcularDatosGrafico()} dataKey="value" nameKey="name" outerRadius={80} fill="#8884d8">
                  {calcularDatosGrafico().map((entry, index) => (
                    <Cell key={index} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Metricas;
