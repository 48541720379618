import { useNavigate } from 'react-router-dom';
import { AccountCircle } from '@mui/icons-material';
import { AppBar, CssBaseline, Drawer, IconButton, List, ListItem, ListItemText, Toolbar, Container, Typography, Menu, MenuItem } from '@mui/material';
import { Link, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { useState, useEffect } from 'react'; // Importamos useState y useEffect
import { jwtDecode } from 'jwt-decode'; // Importa jwt-decode
import AuditResult from './AuditResult';
import Categorias from './Categorias';
import Checklist from './Checklist';
import Contactos from './Contactos';
import Country from './Country';
import DistribucionCorreo from './DistribucionCorreo';
import Documentos from './Documentos';
import Organizacion from './Organizacion';
import Permisos from './Permisos';
import PermisosSucursal from './PermisosSucursal';
import RegulacionesSucursal from './RegulacionesSucursal';
import Regulation from './Regulation';
import Requerimientos from './Requerimientos';
import SubCategorias from './SubCategorias';
import Sucursales from './Sucursales';
import TipoDocumento from './TipoDocumento';
import VistaRegulaciones from './Vista de Regulaciones';
import VistaChecklist from './Vista de Checklist';
import Login from './LoginPage';
import Vistadocumentos from './Vista Documentos';
import Metricas from './Metricas';

const drawerWidth = 240;

const DashboardPage = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [username, setUsername] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [modulosPermitidos, setModulosPermitidos] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      setUsername(decodedToken.nombre);
      setIsAdmin(decodedToken.admin);
      setModulosPermitidos(decodedToken.MODULOS || []);
    }
  }, []); // Este efecto se ejecuta solo una vez cuando el componente se monta

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    handleClose();
    navigate('/');
  };

  return (
    <Container>
      <div style={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" style={{ zIndex: 1201 }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => navigate('/dashboard/metricas')} style={{ marginRight: '16px' }}>
              <img src={'https://legistool-images.nyc3.digitaloceanspaces.com/public/Logo.png'} alt="Logo" style={{ height: '40px' }} />
            </IconButton>
            <Typography variant="h6" noWrap component="div" style={{ flexGrow: 1 }}>
              
            </Typography>
            <Typography variant="body1" component="div">
              {username}
            </Typography>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="persistent"
          open={true}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
          }}
        >
          <Toolbar />
          <div>
            <List>
              {isAdmin ? (
                <>
                  <ListItem component={Link} to="/dashboard/metricas">
                    <ListItemText primary="Metricas" />
                  </ListItem>
                  <ListItem component={Link} to="/dashboard/categorias">
                    <ListItemText primary="Categorías" />
                  </ListItem>
                  <ListItem component={Link} to="/dashboard/checklist">
                    <ListItemText primary="Checklist" />
                  </ListItem>
                  <ListItem component={Link} to="/dashboard/contactos">
                    <ListItemText primary="Contactos" />
                  </ListItem>
                  <ListItem component={Link} to="/dashboard/distribucion-correo">
                    <ListItemText primary="Distribución de Correos" />
                  </ListItem>
                  <ListItem component={Link} to="/dashboard/documentos">
                    <ListItemText primary="Documentos" />
                  </ListItem>
                  <ListItem component={Link} to="/dashboard/organizacion">
                    <ListItemText primary="Organización" />
                  </ListItem>
                  <ListItem component={Link} to="/dashboard/paises">
                    <ListItemText primary="Paises" />
                  </ListItem>
                  <ListItem component={Link} to="/dashboard/permisos">
                    <ListItemText primary="Permisos" />
                  </ListItem>
                  <ListItem component={Link} to="/dashboard/permisossucursales">
                    <ListItemText primary="Permisos por sucursal" />
                  </ListItem>
                  <ListItem component={Link} to="/dashboard/regulation">
                    <ListItemText primary="Regulaciones" />
                  </ListItem>
                  <ListItem component={Link} to="/dashboard/regulacionessucursales">
                    <ListItemText primary="Regulaciones por sucursal" />
                  </ListItem>
                  <ListItem component={Link} to="/dashboard/requerimientos">
                    <ListItemText primary="Requerimientos" />
                  </ListItem>
                  <ListItem component={Link} to="/dashboard/resultadodeauditoria">
                    <ListItemText primary="Resultado de Auditoria" />
                  </ListItem>
                  <ListItem component={Link} to="/dashboard/subcategorias">
                    <ListItemText primary="Sub Categorias" />
                  </ListItem>
                  <ListItem component={Link} to="/dashboard/sucursales">
                    <ListItemText primary="Sucursales" />
                  </ListItem>
                  <ListItem component={Link} to="/dashboard/tipodocumento">
                    <ListItemText primary="Tipo de Documento" />
                  </ListItem>
                  <ListItem component={Link} to="/dashboard/vistaregulaciones">
                    <ListItemText primary="Vista de Regulaciones" />
                  </ListItem>
                  <ListItem component={Link} to="/dashboard/vistachecklist">
                    <ListItemText primary="Vista de Checklist" />
                  </ListItem>
                  <ListItem component={Link} to="/dashboard/vistadocumentos">
                    <ListItemText primary="Vista de Documentos" />
                  </ListItem>
                </>
              ) : (
                <>
                  {modulosPermitidos.includes("vista-metricas") && (
                    <ListItem component={Link} to="/dashboard/metricas">
                      <ListItemText primary="Metricas" />
                    </ListItem>
                  )}
                  {modulosPermitidos.includes("vista-regulaciones") && (
                    <ListItem component={Link} to="/dashboard/vistaregulaciones">
                      <ListItemText primary="Vista de Regulaciones" />
                    </ListItem>
                  )}
                  {modulosPermitidos.includes("vista-checklist") && (
                    <ListItem component={Link} to="/dashboard/vistachecklist">
                      <ListItemText primary="Vista de Checklist" />
                    </ListItem>
                  )}
                  {modulosPermitidos.includes("vista-regulaciones") && (
                    <ListItem component={Link} to="/dashboard/vistadocumentos">
                      <ListItemText primary="Vista de Documentos" />
                    </ListItem>
                  )}
                </>
              )}
            </List>
          </div>
        </Drawer>
        <main style={{ flexGrow: 1, padding: '24px', marginLeft: 0 }}>
          <Toolbar />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/metricas" element={<Metricas isAdmin={isAdmin} />} />
            <Route path="/organizacion" element={<Organizacion />} />
            <Route path="/paises" element={<Country />} />
            <Route path="/sucursales" element={<Sucursales />} />
            <Route path="/distribucion-correo" element={<DistribucionCorreo />} />
            <Route path="/contactos" element={<Contactos />} />
            <Route path="/categorias" element={<Categorias />} />
            <Route path="/checklist" element={<Checklist />} />
            <Route path="/subcategorias" element={<SubCategorias />} />
            <Route path="/permisos" element={<Permisos />} />
            <Route path="/tipodocumento" element={<TipoDocumento />} />
            <Route path="/permisossucursales" element={<PermisosSucursal />} />
            <Route path="/regulation" element={<Regulation />} />
            <Route path="/regulacionessucursales" element={<RegulacionesSucursal />} />
            <Route path="/documentos" element={<Documentos />} />
            <Route path="/resultadodeauditoria" element={<AuditResult isAdmin={isAdmin} />} />
            <Route path="/requerimientos" element={<Requerimientos />} />
            <Route path="/vistaregulaciones" element={<VistaRegulaciones isAdmin={isAdmin} />} />
            <Route path="/vistachecklist" element={<VistaChecklist isAdmin={isAdmin} />} />
            <Route path="/vistadocumentos" element={<Vistadocumentos isAdmin={isAdmin} />} />
          </Routes>
        </main>
      </div>
    </Container>
  );
};

export default DashboardPage;
