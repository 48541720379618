import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

const userServicios = [
  {
    avatar: <Avatar alt="Auditorías de cumplimiento legal" src="/static/images/avatar/1.jpg" />,
    name: 'Auditorías de cumplimiento legal',
    servicios:
      "Basadas en la legislación local aplicable, así como los estándares de las normas: ISO 9001:2015 (calidad), ISO 14001:2015 (gestión ambiental), ISO 45001:2018 (salud ocupacional), ISO 50001:2018 (energía), FSSC 22000 (inocuidad). Ayuda a la empresa a identificar sus impactos y riesgos de su operació.",
  },
  {
    avatar: <Avatar alt="Actualización normativa y atención de consultas relacionadas" src="/static/images/avatar/2.jpg" />,
    name: 'Actualización normativa y atención de consultas relacionadas',
    servicios:
      "Los cambios normativos requieren un seguimiento constante, así como preparación de los colaboradores en temas ambientales, gestión de residuos, salud ocupacional entre otros. Ofrecemos el servicio de monitoreo de cambios en la legislación, así como reportes de estos asociados a sus operaciones.",
  },
  {
    avatar: <Avatar alt="Asesorías en temas ambientales" src="/static/images/avatar/3.jpg" />,
    name: 'Asesorías y capaciotaciones en temas ambientales',
    servicios:
      'Asesorías enfocadas en las necesidades del cliente y en la continuidad del negocio. Las capacitaciones buscan apoyar a las organizaciones en mantenerse actualizadas con los cambios normativos de la legislación aplicable a sus operaciones',
  },
  {
    avatar: <Avatar alt="Atención de procesos judiciales y en sede administrativa" src="/static/images/avatar/4.jpg" />,
    name: 'Atención de procesos judiciales y en sede administrativa',
    servicios:
      "Ante denuncias en sede judicial o administrativa contamos con la experiencia necesaria para asesorar a nuestros clientes de la mejor manera, con los más altos estándares de calidad.",
  },
  {
    avatar: <Avatar alt="Due Diligence Ambiental" src="/static/images/avatar/5.jpg" />,
    name: 'Due Diligence Ambiental',
    servicios:
      "Esta asesoría es esencial para evitar futuras contingencias, o asegurarse de contabilizarlas de manera adecuada dentro de una transacción para la compra o venta de activos.",
  },
  {
    avatar: <Avatar alt="Carbono Neutralidad" src="/static/images/avatar/6.jpg" />,
    name: 'Carbono Neutralidad',
    servicios:
      "Apoyamos en la construcción y verificación del sistema de gestión basado en los estándares INTE B5:2021, ISO 14064:2018 y el GHG Protocol.",
  },
];

export default function Servicios() {


  return (
    <Container
      id="Servicios"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
        Servicios de Derecho Ambiental
        </Typography>
        <Typography variant="body1" color="text.secondary">
        Ante los desafíos actuales de las empresas en temas ambientales, sociales y de gobernanza, podemos apoyar a nuestros clientes en diferentes aspectos del desarrollo de sus negocios.

        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userServicios.map((servicios, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
            
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
                p: 1,
              }}
            >
              <CardContent>
              <CardHeader
                  avatar={servicios.avatar}
                  title={servicios.name}
                />
                <Typography variant="body2" color="text.secondary">
                  {servicios.servicios}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  pr: 2,
                }}
              >
               
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
