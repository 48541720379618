// src/mocks/axios.js
const axios = {
    post: (url, data) => {
      if(data.email === "test.user@email.com" && data.password === "DemoLegistoolTest") {
        return new Promise((resolve, reject) => {
          if (url === '/api/login') {
            // Simulate successful login
            resolve({
              data: {
                token: 'eyJhbGciOiJIUzI1NiJ9.eyJhZG1pbiI6ZmFsc2UsIm5vbWJyZSI6IlRlc3QgVXNlciIsIk1PRFVMT1MiOlsidmlzdGEtY2hlY2tsaXN0IiwidmlzdGEtcmVndWxhY2lvbmVzIiwidmlzdGEtZG9jdW1lbnRvcyIsInZpc3RhLW1ldHJpY2FzIl19.dMyYoS58Wb7XkT_S5UPDgzbn0GE3tDlcF9eZdLaT2d4',
              },
            });
          } else if (url === '/api/refresh-token') {
            // Simulate token refresh
            resolve({
              data: {
                token: 'eyJhbGciOiJIUzI1NiJ9.eyJhZG1pbiI6ZmFsc2UsIm5vbWJyZSI6IlRlc3QgVXNlciIsIk1PRFVMT1MiOlsidmlzdGEtY2hlY2tsaXN0IiwidmlzdGEtcmVndWxhY2lvbmVzIiwidmlzdGEtZG9jdW1lbnRvcyIsInZpc3RhLW1ldHJpY2FzIl19.dMyYoS58Wb7XkT_S5UPDgzbn0GE3tDlcF9eZdLaT2d4',
              },
            });
          } else {
            reject(new Error('Unknown endpoint'));
          }
        });
      }
      else{
        if(data.email === "admin.user@email.com" && data.password === "Admin$1.Marco") {
          return new Promise((resolve, reject) => {
            if (url === '/api/login') {
              // Simulate successful login
              resolve({
                data: {
                  token: 'eyJhbGciOiJIUzI1NiJ9.eyJhZG1pbiI6dHJ1ZSwibm9tYnJlIjoiQWRtaW4gVXNlciIsIk1PRFVMT1MiOlsiYWxsIl19.1IYplHRTVIHXegJtEAEHevUBJ0zZRoZtr4JHnGA0ZCM',
                },
              });
            } else if (url === '/api/refresh-token') {
              // Simulate token refresh
              resolve({
                data: {
                  token: 'eyJhbGciOiJIUzI1NiJ9.eyJhZG1pbiI6dHJ1ZSwibm9tYnJlIjoiQWRtaW4gVXNlciIsIk1PRFVMT1MiOlsiYWxsIl19.1IYplHRTVIHXegJtEAEHevUBJ0zZRoZtr4JHnGA0ZCM',
                },
              });
            } else {
              reject(new Error('Unknown endpoint'));
            }
          });
        }
      }
      
    },
  };
  
  export default axios;
  